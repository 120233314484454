import { Notify, Dialog, Quasar } from 'quasar'
import langKo from 'quasar/lang/ko-KR'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import 'quasar/dist/quasar.css'
import '@quasar/extras/animate/flash.css'

export default {
  install (app) {
    app.use(Quasar, {
      lang:langKo,
      config: {
        dark: true,
        brand: {
          primary: '#FF8E03',
          secondary: '#FF8E03',
          accent: '#26a69a',

          dark: '#242424',
        },
        notify: {
          position: 'top',
          type: 'negative',
          timeout: 2000,
          actions: [
            { icon: 'close', color: 'white', size: 'sm', round: true }
          ]
        },
      },
      plugins: {
        Notify,
        Dialog,
      },
    })
  }
}
